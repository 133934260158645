<template>
  <div v-if="clientOrders()">
    <div class="text-center container">
      <h1>ЗАКАЗ КЛИЕНТА</h1>

      <div v-if="spinnerLoading" class="d-flex justify-content-center mt-3">
        <div
          class="spinner-border text-primary d-flex justify-content-center"
          role="status"
        ></div>
      </div>

      <div v-if="'Number' in doc">
        <div class="row mt-2">
          <div class="col-sm">
            <button
              class="btn btn-success w-100"
              @click="saveDoc(true)"
              :disabled="disabledDocSave"
            >
              ПРОВЕСТИ
            </button>
          </div>

          <div class="col-sm">
            <button
              class="btn btn-primary w-100"
              @click="saveDoc(false)"
              :disabled="disabledDocSave"
            >
              СОХРАНИТЬ
            </button>
          </div>

          <div class="col-sm">
            <button
              class="btn btn-info text-white w-100"
              @click="setГотовДляРеализации()"
              :disabled="disabledDocSave || doc.ЗКР_ГотовДляРеализации"
            >
              <span v-if="doc.ЗКР_ГотовДляРеализации">
                ГОТОВ ДЛЯ РЕАЛИЗАЦИИ
              </span>

              <span v-if="!doc.ЗКР_ГотовДляРеализации">
                УСТАНОВИТЬ ГОТОВ ДЛЯ РЕАЛИЗАЦИИ
              </span>
            </button>
          </div>

          <div class="col-sm">
            <router-link
              class="btn btn-secondary w-100"
              :to="{ path: '/clientOrders' }"
              >НАЗАД</router-link
            >
            <p v-if="errorMsg" class="fs-5 fw-bold">{{ errorMsg }}</p>
          </div>
        </div>

        <div class="row row-cols-3 mt-2">
          <div class="col-sm" style="max-width:40px">
            <svg
              v-if="doc.Posted"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="32"
              height="32"
            >
              <path
                fill="#71f702"
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
              />
            </svg>
            <svg
              v-if="doc.DeletionMark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              width="32"
              height="32"
            >
              <path
                fill="#ff0000"
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
              />
            </svg>
          </div>
          <div class="col-sm">{{ doc.Number }}</div>
          <div class="col-sm">{{ dateFormat(doc.Date) }}</div>
        </div>

        <div class="row mt-3">
          <div class="col-sm">
            <CatalogWidget
              v-model:item="doc.Склад_Key"
              type="Склады"
              placeholder="Склад"
              show-placeholder
              :fetch-all="true"
            />
          </div>
          <div class="col-sm">
            <CatalogWidget
              v-model:item="doc.Организация_Key"
              type="Организации"
              placeholder="Организация"
              show-placeholder
              :fetch-all="true"
            />
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm mt-3">
            <div class="row">
              <CatalogWidget
                v-model:item="doc.Партнер_Key"
                type="Партнеры"
                placeholder="Партнер"
                show-placeholder
                :fetch-all="false"
                @change="onChangePartner()"
              />
            </div>
            <div class="row mt-3">
              <CatalogWidget
                v-model:item="doc.Контрагент_Key"
                type="Контрагенты"
                placeholder="Контрагент"
                show-placeholder
                :fetch-all="false"
              />
            </div>
          </div>
          <div class="col-sm">
            <div class="row mt-3">
              <CatalogWidget
                v-model:item="doc.Договор_Key"
                type="ДоговорыКонтрагентов"
                placeholder="Договор"
                show-placeholder
                :fetch-all="false"
                :filter-odata="filterOdataДоговор"
              />
            </div>
            <div class="row mt-3">
              <CatalogWidget
                v-model:item="doc.Соглашение_Key"
                type="СоглашенияСКлиентами"
                placeholder="Соглашение"
                show-placeholder
                :fetch-all="false"
                :filter-odata="filterOdataСоглашение"
              />
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm">
            <input
              v-model="doc.Комментарий"
              type="text"
              class="form-control"
              placeholder="Комментарий"
            />
            <span class="text-muted placeholder-font">Комментарий</span>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm">
            <input
              v-model="doc.ДатаОтгрузки"
              type="date"
              class="form-control"
              placeholder="Дата отгрузки"
            />
            <span class="text-muted placeholder-font">Дата отгрузки</span>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-sm">
            <button
              class="btn btn-info w-100 text-white"
              @click="setЗонаДоставки('самовывоз')"
            >
              САМОВЫВОЗ
            </button>
          </div>
          <div class="col-sm">
            <button
              class="btn btn-info w-100 text-white"
              @click="setЗонаДоставки('доставка')"
            >
              ДОСТАВКА
            </button>
          </div>
        </div>

        <div class="accordion mt-3" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                ТОВАРЫ
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="row ">
                  <div class="col-sm text-start">
                    <button class="btn btn-warning" @click="addRow()">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="#ffffff"
                          d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z"
                        />
                      </svg>
                    </button>

                    <button
                      class="btn btn-info mx-2 text-white"
                      @click="fillВариантОбеспечения()"
                    >
                      Заполнить обеспечение
                    </button>
                    <button
                      class="btn btn-info mx-2 text-white"
                      @click="fillЦеныПоследнихПродаж()"
                    >
                      Заполнить цены последних продаж
                    </button>
                  </div>
                </div>
                <div class="row fw-bold">
                  <div class="col-sm" style="min-width:350px">Номенклатура</div>
                  <div class="col-sm">Кол-во</div>
                  <div class="col-sm">Цена</div>
                  <div class="col-sm">Сумма</div>
                  <div class="col-sm">Вид цены</div>
                  <div class="col-sm" style="max-width:40px"></div>
                </div>

                <div
                  v-for="row in doc.Товары"
                  :key="row.LineNumber"
                  class="row border border-1 mt-2"
                >
                  <div class="col-sm" style="min-width:350px">
                    <CatalogWidget
                      v-model:item="row.Номенклатура_Key"
                      type="Номенклатура"
                      placeholder="Номенклатура"
                      :fetch-all="false"
                      @change="getProductPrice(row)"
                      :filter-odata="filterOdataНоменклатура"
                      input-wide
                    />
                  </div>
                  <div class="col-sm">
                    <input
                      v-model="row.КоличествоУпаковок"
                      type="number"
                      step="0.001"
                      class="form-control"
                      placeholder="Количество"
                      @change="getProductPrice(row)"
                    />
                  </div>
                  <div class="col-sm">
                    <input
                      v-model="row.Цена"
                      type="number"
                      step="0.01"
                      class="form-control"
                      placeholder="Цена"
                      @change="calcSum(row)"
                    />
                  </div>
                  <div class="col-sm">
                    <input
                      v-model="row.Сумма"
                      type="number"
                      step="0.01"
                      class="form-control"
                      placeholder="Сумма"
                    />
                  </div>
                  <div class="col-sm">
                    <div class="row">
                      <CatalogWidget
                        v-model:item="row.ВидЦены_Key"
                        type="ВидыЦен"
                        placeholder="Вид цены"
                        :fetch-all="true"
                        @change="getProductPrice(row)"
                      />
                    </div>
                    <div class="row px-3">
                      {{ row.ВариантОбеспечения }}
                    </div>
                  </div>
                  <div class="col-sm mx-0 px-0" style="max-width:40px">
                    <button
                      class="btn btn-light mx-0 px-0"
                      @click="deleteRow(row)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                        width="22"
                        height="22"
                      >
                        <path
                          fill="#ff0000"
                          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                ДОСТАВКА

                <CatalogWidget
                  v-model:item="doc.ЗонаДоставки_Key"
                  type="ЗоныДоставки"
                  placeholder="Зона доставки не выбрана"
                  :fetch-all="true"
                  disabled
                  style="width: 200px"
                  class="mx-3"
                />
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="row mt-2 border border-1">
                  <div class="col-sm" style="max-width: 150px">
                    Способ доставки
                  </div>
                  <div class="col-sm">
                    {{ doc.СпособДоставки }}
                  </div>
                </div>

                <div class="row mt-2 border border-1">
                  <div class="col-sm" style="max-width: 150px">
                    Адрес доставки
                  </div>
                  <div class="col-sm">
                    <textarea
                      v-model="doc.АдресДоставки"
                      type="text"
                      class="form-control"
                      placeholder="Адрес доставки"
                    />
                  </div>
                </div>

                <div class="row mt-2 border border-1">
                  <div class="col-sm" style="max-width: 150px">
                    Зона доставки
                  </div>
                  <div class="col-sm">
                    <CatalogWidget
                      v-model:item="doc.ЗонаДоставки_Key"
                      type="ЗоныДоставки"
                      placeholder="Зона доставки"
                      :fetch-all="true"
                    />
                  </div>

                  <div class="row mt-2 border border-1">
                    <div class="col-sm" style="max-width: 150px">
                      Доп инф по доставке
                    </div>
                    <div class="col-sm">
                      <textarea
                        v-model="doc.ДополнительнаяИнформацияПоДоставке"
                        type="text"
                        class="form-control"
                        placeholder="Дополнительная информация по доставке"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                ДОПОЛНИТЕЛЬНО
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                ДОПОЛНИТЕЛЬНО
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, nextTick } from "vue";
import useCommonMixin from "@/components/commonMixin.js";
import CatalogWidget from "@/components/CatalogWidget.vue";
import router from "../router";

export default {
  components: { CatalogWidget },
  props: { refKey: String },
  setup(props) {
    const spinnerLoading = ref(false);
    const errorMsg = ref("");

    const doc = ref({});
    const preloadedНоменклатура = ref([]);

    const {
      dateFormat,
      getDoc1C,
      saveDoc1C,
      getProductPrice1C,
      getKontragentByPartner1C,
      getUserByFIO1C,
      userSklad,
      // getCatlg1C,
    } = useCommonMixin();

    //const  isNew = computed( ()=>!('Ref_Key' in doc.value && doc.value.Ref_Key))
    const isNew = computed(() => !props.refKey || props.refKey == "__NEW__");

    const clientOrders = () => {
      return JSON.parse(localStorage.getItem("clientOrders"));
    };

    const getDoc = async () => {
      console.log("isNew", isNew.value);
      if (isNew.value) {
        let currentTime = new Date();
        currentTime.setHours(currentTime.getHours());
        let date = currentTime.toISOString().substring(0, 19);

        doc.value = {
          Posted: false,
          Number: "",
          Date: date,
          ДатаОтгрузки: date.substring(0, 10),
          Склад_Key: userSklad(),
          Организация_Key: "cfd16d3d-e911-4669-b4d0-38f628ce0088", //Технология вкуса
          Договор_Key: "",
          Соглашение_Key: "",
          Партнер_Key: "",
          Контрагент_Key: "",
          Комментарий: "",
          ЦенаВключаетНДС: true,
          НеОтгружатьЧастями: true,
          СкидкиРассчитаны: true,
          СпособДоставки: "КПолучателюОпределяетСлужбаДоставки",
          ПорядокРасчетов: "ПоНакладным",
          СуммаДокумента: 0,
          Товары: [
            {
              LineNumber: 1,
              Номенклатура_Key: "",
              КоличествоУпаковок: "",
              Количество: "",
              Цена: "",
              Сумма: 0,
              СуммаСНДС: 0,
              ВидЦены_Key: "",
            },
          ],
        };

        let resp = await getUserByFIO1C();
        if (resp.status) {
          doc.value.Менеджер_Key = resp.data.Менеджер_Key;
          doc.value.Автор = resp.data.Автор;
          doc.value.Автор_Type = resp.data.Автор_Type;
        }
        return;
      }

      spinnerLoading.value = true;
      let reqData = {
        type: "ЗаказКлиента",
        refKey: props.refKey,
      };

      let resp = await getDoc1C(reqData);
      console.log("getDocs", resp);
      if (resp.status) {
        spinnerLoading.value = false;
        doc.value = resp.data;
        doc.value.ДатаОтгрузки = doc.value.ДатаОтгрузки.substring(0, 10);
      }
    };

    // const getCatlgs = async () => {
    //   let reqData = {
    //     type: "Номенклатура",
    //   };

    //   let resp = await getCatlg1C(reqData);
    //   if (resp.status) {
    //     preloadedНоменклатура.value = resp.data.value;
    //   }
    // }

    const saveDoc = async (Posted = false) => {
      errorMsg.value = "";
      spinnerLoading.value = true;
      if ("Товары" in doc.value) {
        doc.value["Товары"].forEach((row) => {
          row.Количество = row.КоличествоУпаковок;
          if (!row.ВидЦены_Key) row.ВидЦены_Key = undefined;
        });
      }

      let postedDoc = JSON.parse(JSON.stringify(doc.value));
      postedDoc.Posted = Posted || postedDoc.Posted;
      if (!postedDoc.Договор_Key) postedDoc.Договор_Key = undefined;
      console.log(JSON.stringify(doc.value));
      console.log("СуммаДокумента", doc.value.СуммаДокумента);

      let reqData = {
        type: "ЗаказКлиента",
        data: postedDoc,
      };

      let resp = await saveDoc1C(reqData);
      console.log("saveDoc1C", resp);
      if (resp.status) {
        spinnerLoading.value = false;
        doc.value = resp.data;
        doc.value.ДатаОтгрузки = doc.value.ДатаОтгрузки.substring(0, 10);
      } else {
        errorMsg.value = "Ошибка проведения, проверьте цены. " + resp.error;
      }
      if ("Ref_Key" in resp.data)
        router.push({
          name: "clientOrder",
          params: { refKey: resp.data.Ref_Key },
        });
    };

    const setГотовДляРеализации = async () => {
      let ready = true;
      if (!("Товары" in doc.value)) {
        ready = false;
      } else {
        doc.value["Товары"].forEach((row) => {
          if (row.ВариантОбеспечения != "Отгрузить") ready = false;
        });
      }

      if (!ready) {
        let confirm = window.confirm(`Заказ не готов к реализации`);
        console.log(confirm);
        return;
      }

      doc.value.ЗКР_ГотовДляРеализации = true;
      await saveDoc(true);
    };

    const getProductPrice = async (row) => {
      await nextTick();
      if (
        row.ВидЦены_Key &&
        row.ВидЦены_Key != "00000000-0000-0000-0000-000000000000"
      ) {
        row.ЦенаПоПоследнимПродажам = false;
        let reqData = [];
        row.Количество = row.КоличествоУпаковок;
        row.Склад_Key = doc.value.Склад_Key;
        reqData.push(row);

        let resp = await getProductPrice1C(reqData);
        console.log("getProductPrice", resp);
        if (!resp.status) return;

        row.ВариантОбеспечения = "";
        row.Цена = resp.data[0].Цена;
        row.Сумма = resp.data[0].Сумма;
        row.СтавкаНДС_Key = resp.data[0].СтавкаНДС_Key;
        row.Ставка = resp.data[0].Ставка;
      }
      calcSum(row);
    };

    const fillВариантОбеспечения = async () => {
      for await (const row of doc.value.Товары) {
        let reqData = [];
        row.Количество = row.КоличествоУпаковок;
        row.Склад_Key = doc.value.Склад_Key;
        row.ЦенаПоПоследнимПродажам = false;
        reqData.push(row);

        let resp = await getProductPrice1C(reqData);
        console.log("fillВариантОбеспечения", resp);
        if (!resp.status) continue;
        row.ВариантОбеспечения = resp.data[0].ВариантОбеспечения;
      }
    };

    const fillЦеныПоследнихПродаж = async () => {
      for await (const row of doc.value.Товары) {
        let reqData = [];
        row.Количество = row.КоличествоУпаковок;
        row.Склад_Key = doc.value.Склад_Key;
        row.Дата = doc.value.Date;
        row.Контрагент_Key = doc.value.Контрагент_Key;
        row.ЦенаПоПоследнимПродажам = true;
        console.log(row);
        reqData.push(row);

        let resp = await getProductPrice1C(reqData);
        console.log("fillЦеныПоследнихПродаж", resp);
        if (!resp.status) continue;
        row.Цена = resp.data[0].Цена;
        row.Сумма = resp.data[0].Сумма;
        row.СтавкаНДС_Key = resp.data[0].СтавкаНДС_Key;
        row.Ставка = resp.data[0].Ставка;
        row.ВидЦены_Key = resp.data[0].ВидЦены_Key;
        calcSum(row);
      }
    };

    const calcSum = (row) => {
      row.Сумма = Number((row.Цена * row.КоличествоУпаковок).toFixed(2));
      row.СуммаНДС = (row.Сумма * row.Ставка) / (100 + row.Ставка);
      row.СуммаСНДС = row.Сумма;

      let docSum = doc.value.Товары.reduce((acc, cur) => acc + cur.Сумма, 0);
      doc.value.СуммаДокумента = Number(docSum.toFixed(2));
      console.log("doc.value.СуммаДокумента", doc.value.СуммаДокумента);
    };

    const addRow = () => {
      let LineNumber = 1;
      if (doc.value.Товары.length > 0) {
        LineNumber =
          doc.value.Товары[doc.value.Товары.length - 1].LineNumber + 1;
      }

      doc.value.Товары.push({
        LineNumber: LineNumber,
        Номенклатура_Key: "",
        КоличествоУпаковок: "",
        Количество: "",
        Цена: "",
        Сумма: 0,
      });
    };

    const deleteRow = (row) => {
      let confirm = window.confirm(`Удалить позицию?`);
      if (!confirm) {
        return;
      }

      let rowIndex = doc.value.Товары.findIndex(
        (item) => item.LineNumber == row.LineNumber
      );
      if (rowIndex >= 0) {
        doc.value.Товары.splice(rowIndex, 1);
      }
    };

    const onChangePartner = async () => {
      console.log("onChangePartner", doc.value.Партнер_Key);
      let resp = await getKontragentByPartner1C({
        Партнер_Key: doc.value.Партнер_Key,
        Организация_Key: doc.value.Организация_Key,
      });
      console.log("onChangePartner resp", resp);
      if (resp.status) {
        doc.value.Контрагент_Key = resp.data.Контрагент_Key;
        doc.value.АдресДоставки = resp.data.АдресДоставки;
        doc.value.Соглашение_Key = resp.data.Соглашение_Key;
        doc.value.ЗонаДоставки_Key = resp.data.ЗонаДоставки_Key;
      }
    };

    const setЗонаДоставки = async (type) => {
      if (type == "самовывоз") {
        doc.value.ЗонаДоставки_Key = "9a355931-568a-11ed-ab3c-3ca82a152c77";
      }

      if (type == "доставка") {
        let resp = await getKontragentByPartner1C({
          Партнер_Key: doc.value.Партнер_Key,
          Организация_Key: doc.value.Организация_Key,
        });
        console.log("onChangePartner resp", resp);
        if (resp.status) {
          doc.value.ЗонаДоставки_Key = resp.data.ЗонаДоставки_Key;
        }
      }
    };

    const filterOdataСоглашение = computed(() => {
      let result = "";
      if (doc.value.Партнер_Key)
        result = `(Типовое eq true) or Партнер_Key eq guid'${doc.value.Партнер_Key}'`;
      return result;
    });

    const filterOdataДоговор = computed(() => {
      let result = "";
      if (doc.value.Организация_Key)
        result = `Организация_Key eq guid'${doc.value.Организация_Key}' and Контрагент_Key eq guid'${doc.value.Контрагент_Key}'`;
      return result;
    });

    const filterOdataНоменклатура = computed(() => {
      let result = "";
      result = `DeletionMark eq false`;
      return result;
    });

    const disabledDocSave = computed(() => {
      return (
        spinnerLoading.value ||
        doc.value.DeletionMark ||
        doc.value.Товары.length == 0 ||
        !doc.value.Склад_Key ||
        !doc.value.Партнер_Key ||
        !doc.value.Контрагент_Key ||
        !doc.value.Организация_Key
      );
    });

    

    onMounted(async () => {
      //await getCatlgs()
      await getDoc();
    });

    return {
      doc,
      spinnerLoading,
      dateFormat,
      saveDoc,
      errorMsg,
      isNew,
      getProductPrice,
      calcSum,
      addRow,
      onChangePartner,
      filterOdataСоглашение,
      deleteRow,
      disabledDocSave,
      clientOrders,
      fillВариантОбеспечения,
      filterOdataДоговор,
      fillЦеныПоследнихПродаж,
      userSklad,
      filterOdataНоменклатура,
      setЗонаДоставки,
      preloadedНоменклатура,
      setГотовДляРеализации,
    };
  },
};
</script>
