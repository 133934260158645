import { computed, ref, nextTick } from "vue";
import { Html5Qrcode } from "html5-qrcode";

export default () => {

  const collectedGoodsNumber = computed(() => {
    return (order) => {
      let result = 0;
      order.goods.forEach((item) => {
        if (item.collected) result++;
      });
      return result;
    };
  })

  const isMarksCollected = computed(() => {
    return (order) => {
      let result = true;

      if(order.client.indexOf("Интернет Покупатель") >= 0) {
        order.goods.forEach((item) => {
          if (item.needMarks && ((item.marks.length != item.qtyFact) || item.qtyFact == 0)) {
            result = false
          }
        })
      }
      return result;
    };
  })

  const checkedGoodsNumber = computed(() => {
    return (order) => {
      let result = 0;
      order.goods.forEach((item) => {
        if (item.checked == "+" || item.checked == "-") result++;
      });
      return result;
    };
  })

  const scrollToId = async (Id) => {
    await nextTick();
    let el = document.getElementById(Id);
    el.scrollIntoView({ block: "start", behavior: "smooth" });
  };
  

  const dateFormat = (date) => {
    let result = ''
    if (date) {
      let dateFormated = new Date(date);
      result = dateFormated.toLocaleString()
    }
    
    return result;
  };

  const isMobile = computed(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  })


  const saveDocCorrection = async (doc) => {
    doc.spinner = true;
    let res = await fetch(
      process.env.VUE_APP_API_URL + `/saveDocCorrection/`,
      {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(doc),
      }
    );
    console.log(res.status);

    if (res.status == 200) {
      let docRes = await res.json();
      doc.spinner = false;
      doc.edit = false;
      doc._id = docRes._id
    }
  };

  const saveDocIncome = async (doc) => {
    doc.spinner = true;
    let res = await fetch(
      process.env.VUE_APP_API_URL + `/saveDocIncome/`,
      {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(doc),
      }
    );
    console.log(res.status);

    if (res.status == 200) {
      let docRes = await res.json();
      doc.spinner = false;
      doc.edit = false;
      doc._id = docRes._id
    }
  };

  const getDocsCorrection = async (dateFrom, dateTo) => {
    let result = { status: false, data: [] }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
      `/getDocCorrection/dateFrom/${dateFrom}/dateTo/${dateTo}`,
      {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      }
    );

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const getSkladOrderDraft = async (dateFrom, dateTo, store) => {
    let result = { status: false, data: [] }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
      `/getSkladOrderDraft/dateFrom/${dateFrom}/dateTo/${dateTo}/store/${store}`,
      {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      }
    );

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const getDocsIncome = async (dateFrom, dateTo) => {
    let result = { status: false, data: [] }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
      `/getDocIncome/dateFrom/${dateFrom}/dateTo/${dateTo}`,
      {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      }
    );

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const getDocIncome = async (docId) => {
    let result = { status: false, data: [] }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
      `/getDocIncome/byId/${docId}`,
      {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      }
    );

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const getDocCorrection = async (docId) => {
    let result = { status: false, data: [] }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
      `/getDocCorrection/byId/${docId}`,
      {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      }
    );

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const getPersons = async () => {
    let result = { status: false, data: [] }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
      `/getPersons/`,
      {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      }
    );

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const getProducts = async () => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + "/getProducts/", {
      method: "GET",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
      },
    });
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const getReportRefundsData = async (dateFrom, dateTo) => {
    let result = { status: false, data: [] }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
        `/getReportRefunds/dateFrom/${dateFrom}/dateTo/${dateTo}`,
      {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      }
    );
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const getPositions = async () => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + "/getPositions/", {
      method: "GET",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
      },
    });
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const getStores = async () => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + "/getStores/", {
      method: "GET",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
      },
    });
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const getIncomeTypes = async () => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + "/getIncomeTypes/", {
      method: "GET",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
      },
    });
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const getOutcomeTypes = async () => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + "/getOutcomeTypes/", {
      method: "GET",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
      },
    });
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const getProductBalance = async () => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + "/getProductBalance/", {
      method: "GET",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
      },
    });
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const getProductBalanceRows = async (id) => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + "/getProductBalanceRows/" + id, {
      method: "GET",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
      },
    });
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const getProductBalanceRowsByProduct = async (productId, storeName) => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + "/getProductBalanceRows/byProduct/" + productId + "/storeName/" + storeName, {
      method: "GET",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
      },
    });
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const postProductBalanceToInventory = async (row) => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + `/postProductBalanceToInventory/${row._id}`, {
      method: "POST",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
      },
    });
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const getSkladRevision = async (storeId, dateFrom, dateTo) => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + `/getSkladRevision/${storeId}/${dateFrom}/${dateTo}`, {
      method: "GET",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
      },
    });
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const getLastInventories = async (storeId, date) => {
    console.log('storeId, date', storeId, date);
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + `/getLastInventories/${storeId}/${date.slice(0, 10)}`, {
      method: "GET",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
      },
    });
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const initSkladRevision = async (storeId) => {
    let result = { status: false, data: [] }
    if (!storeId) return result
    let res = await fetch(process.env.VUE_APP_API_URL + "/initSkladRevision/" + storeId, {
      method: "POST",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
          "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify({cmd: 'init'})
    });
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };
  

  const saveSkladRevision = async (data) => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + "/saveSkladRevision/", {
      method: "POST",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
          "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data)
    });
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const openSkladOrder = async (orderId) => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + "/openSkladOrder/" + orderId, {
      method: "GET",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };
  

  const postProductBalance = async (data) => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + "/postProductBalance/", {
      method: "POST",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data)
    });
    console.log(res.status);

    if (res.status == 200) {
      let resJSON = await res.json()
      if (resJSON.status) {
        result.data = resJSON.data
        result.status = true
      }
    }
    return result
  };

  const postDefaultProductBalancePosition = async (data) => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + "/postDefaultProductBalancePosition/", {
      method: "POST",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data)
    });
    console.log(res.status);

    if (res.status == 200) {
      let resJSON = await res.json()
      if (resJSON.status) {
        result.data = resJSON.data
        result.status = true
      }
    }
    return result
  };

  const postSkladOrderDraftRow = async (data) => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + "/postSkladOrderDraftRow/" + data.docId, {
      method: "POST",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data.rowItem)
    });
    console.log(res.status);

    if (res.status == 200) {
      let resJSON = await res.json()
      if (resJSON.status) {
        result.data = resJSON.data
        result.status = true
      }
    }
    return result
  };

  const postAutoFillProductBalancePosition = async (data) => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + "/postAutoFillProductBalancePosition/", {
      method: "POST",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data)
    });
    console.log(res.status);

    if (res.status == 200) {
      let resJSON = await res.json()
      if (resJSON.status) {
        result.data = resJSON.data
        result.status = true
      }
    }
    return result
  };

  const postProductToSkladOrderDraft = async (data) => {
    let result = { status: false, data: [] }
    //console.log('postProductToSkladOrderDraft', data);
    let res = await fetch(process.env.VUE_APP_API_URL + "/postProductToSkladOrderDraft/fromOrder", {
      method: "POST",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data)
    });
    console.log(res.status);

    if (res.status == 200) {
      let resJSON = await res.json()
      if (resJSON.status) {
        result.data = resJSON.data
        result.status = true
      }
    }
    return result
  };

  const postDriverPriority = async (data) => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + "/postDriverPriority/", {
      method: "POST",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data)
    });
    console.log(res.status);

    if (res.status == 200) {
      let resJSON = await res.json()
      if (resJSON.status) {
        result.data = resJSON.data
        result.status = true
      }
    }
    return result
  };

  const getSkladOrderByInvoiceGuid = async(invoiceGuid) => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + `/getSkladOrderByInvoiceGuid/${invoiceGuid}`, {
      method: "GET",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
      },
    });
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result

  }

  const getAutoFillPositions = async () => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + "/getAutoFillPositions/", {
      method: "GET",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
      },
    });
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };
  
  const getProductBalance1C = async (productId, storeName) => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + `/getProductBalance1C/${productId}/${storeName}`, {
      method: "GET",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
      },
    });
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };


  const deleteProductBalance = async () => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + "/deleteProductBalance/", {
      method: "DELETE",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
      },
    });
    console.log(res.status);

    if (res.status == 200) {
      let resJSON = await res.json()
      if (resJSON.status) {
        result.status = true
      }
    }
    return result
  };

  const deleteProductBalanceById = async (productBalanceId) => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + "/deleteProductBalanceById/" + productBalanceId, {
      method: "DELETE",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
      },
    });
    console.log(res.status);

    if (res.status == 200) {
      let resJSON = await res.json()
      if (resJSON.status) {
        result.status = true
      }
    }
    return result
  };

  const postSkladOrderWeightChecked = async (data) => {
    let result = { status: false, data: [] }
    let res = await fetch(process.env.VUE_APP_API_URL + "/postSkladOrderWeightChecked/", {
      method: "POST",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data)
    });
    console.log(res.status);

    if (res.status == 200) {
      let resJSON = await res.json()
      if (resJSON.status) {
        result.data = resJSON.data
        result.status = true
      }
    }
    return result
  };

  const scanner = ref(false)
  let Html5QrcodeObj = undefined
  const settings = ref({
    camera: ''
  })
  const scannedText = ref('')

  const loadCameraScanner = async () => {
    scannedText.value = ''
    let scannerId = `scanner`
    if (scanner.value) {

      Html5QrcodeObj = new Html5Qrcode(scannerId);
      const config = { fps: 10, qrbox: { width: 250, height: 250 } };
      Html5QrcodeObj.start(
        settings.value.camera,
        config,
        scannerSuccessCallback
      );
    } else {
      try {
        Html5QrcodeObj.stop();
      } catch (error) {
        console.log(error);
      }
      document.getElementById(scannerId).innerHTML = "";
    }
  };

  const scannerSuccessCallback = (decodedText, decodedResult) => {
    //Html5QrcodeObj.pause();
    try {
      Html5QrcodeObj.stop();
      scanner.value = false
    } catch (error) {
      console.log(error);
    }
    scannedText.value = decodedText
    console.log('decodedText, decodedResult', decodedText, decodedResult);
  };

  const scan = (turnOn=false) => {
    if (turnOn) {
      scanner.value = true;  
    } else {
      scanner.value = scanner.value ? false : true;
    }

    let scannerElements = document.getElementsByClassName('scanner')
    scannerElements.forEach(item => {
      item.innerHTML = ""
    })

    if (scanner.value) {
      loadCameraScanner();
    } else {
      try {
        Html5QrcodeObj.stop();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getSettings = async () => {
    const settingsFromLocalstotrage = JSON.parse(
      localStorage.getItem("settings")
    );
    if (settingsFromLocalstotrage == null) {
      return;
    }
    settings.value = settingsFromLocalstotrage;
  };

  const saveSettings = () => {
    localStorage.setItem("settings", JSON.stringify(settings.value));
  };


  const saveOrder = async (order) => {
    //console.log("/saveOrder", order);
    let res = await fetch(process.env.VUE_APP_API_URL + "/saveSkladOrder", {
      method: "POST",
      headers: {
        Authorization:
          "Basic " +
          btoa("username" + ":" + localStorage.getItem("password")),
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(order),
    });
    //console.log(res.status);
    let resJson = undefined;
    if (res.status == 200) {
      resJson = await res.json();
      order.goods.forEach((goodsRow) => {
        let resGoodsIndex = resJson.goods.findIndex(
          (item) => item._id == goodsRow._id
        );
        if (resGoodsIndex >= 0) {
          goodsRow.productPositions =
            resJson.goods[resGoodsIndex].productPositions;
        }
      });
    }
    return resJson;
  }

  
  const getSkladInventories = async (dateFrom, dateTo, store) => {
    let result = { status: false, data: [] }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
      `/getSkladInventories/store/${store}/dateFrom/${dateFrom}/dateTo/${dateTo}/`,
      {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      }
    );

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const getSkladInventory = async (id) => {
    let result = { status: false, data: null }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
      `/getSkladInventory/${id}/`,
      {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      }
    );

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const getSkladInventoryBalance = async (docId) => {
    let result = { status: false, data: null }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
      `/getSkladInventoryBalance/docId/${docId}/`,
      {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      }
    );

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const getSkladInventoryBalanceByProduct = async (store, productId) => {
    let result = { status: false, data: null }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
      `/getSkladInventoryBalanceByProduct/store/${store}/product/${productId}`,
      {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      }
    );

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }
    return result
  };

  const saveSkladInventory = async (doc) => {
    let result = {status: false, data: null}
    doc.spinner = true;
    let res = await fetch(
      process.env.VUE_APP_API_URL + `/saveSkladInventory/`,
      {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(doc),
      }
    );
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }

    return result
  };

  const saveSkladInventoryGoodsRow = async (data) => {
    let result = {status: false, data: null}
    let res = await fetch(
      process.env.VUE_APP_API_URL + `/saveSkladInventoryGoodsRow/`,
      {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(data),
      }
    );
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }

    return result
  };

  const getReportSkladBalance = async () => {
    let result = { status: false, data: null }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
        `/getReportSkladBalance/`,
      {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      }
    );
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }

    return result  
  };

  const postUserHistory = async (data) => {
    let result = {status: false, data: null}
    let res = await fetch(
      process.env.VUE_APP_API_URL + `/postUserHistory/`,
      {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(data),
      }
    );
    console.log(res.status);

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }

    return result
  };

  const postUserHistoryByMetric = async (metric) => {
    let req = {
      metric: metric
    }
    let result = await postUserHistory(req)
    return result
  }

  const getUserHistory = async (userId) => {
    let result = { status: false, data: null }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
        `/getUserHistory/` + userId,
      {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      }
    );

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }

    return result  
  };

  const getSkladAkts = async (data) => {
    let result = { status: false, data: null }
    console.log('getSkladAkts', data);
    let res = await fetch(
      process.env.VUE_APP_API_URL +
        `/getSkladAkts/`,
      {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(data),

      }
    );

    if (res.status == 200) {
      result.data = await res.json();
      result.status = true
    }

    return result  
  };

  const postSkladAkt1C = async (data) => {
    let result = { status: false, data: null }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
        `/postSkladAkt1C/`,
      {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(data),

      }
    );

    if (res.status == 200) {
      result = await res.json();
      result = result.data
    }

    return result  
  };

  const getDoc1C = async (data) => {
    let result = { status: false, data: null }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
        `/odataGetDocument/`,
      {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(data),

      }
    );

    if (res.status == 200) {
      result = await res.json();
      //result = result.data
    }

    return result  
  };

  const saveDoc1C = async (data) => {
    let result = { status: false, data: null }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
        `/odataSaveDocument/`,
      {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(data),

      }
    );

    if (res.status == 200) {
      result = await res.json();
      //result = result.data
    }

    return result  
  };

  const getProductPrice1C = async (data) => {
    let result = { status: false, data: null }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
        `/getProductPrice1C/`,
      {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(data),

      }
    );

    if (res.status == 200) {
      result = await res.json();
      //result = result.data
    }

    return result  
  };

  const getKontragentByPartner1C = async (data) => {
    let result = { status: false, data: null }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
        `/getKontragentByPartner1C/`,
      {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(data),

      }
    );

    if (res.status == 200) {
      result = await res.json();
      //result = result.data
    }

    return result  
  };

  const getUserByFIO1C = async (data) => {
    let result = { status: false, data: null }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
        `/getUserByFIO1C/`,
      {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(data),

      }
    );

    if (res.status == 200) {
      result = await res.json();
      //result = result.data
    }

    return result  
  };

  const getCatlg1C = async (data) => {
    let result = { status: false, data: null }
    let res = await fetch(
      process.env.VUE_APP_API_URL +
        `/odataGetCatalog/`,
      {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(data),

      }
    );

    if (res.status == 200) {
      result = await res.json();
      //result = result.data
    }

    return result  
  };

  const userSklad = () => {
    let result = "";
    if (skladPtz()) {
      result = "ae680c20-1dcf-11ec-7a8e-fa163e36b587";
      return result;
    }

    if (skladApatiti()) {
      result = "bb8b07a8-6ee1-11eb-a3e1-10c37b94c905";
      return result;
    }

    if (skladMyrmansk()) {
      result = "bb8b07ac-6ee1-11eb-a3e1-10c37b94c905";
      return result;
    }

    return result;
  };

  const skladPtz = () => {
    return JSON.parse(localStorage.getItem("skladPtz"));
  };

  const skladApatiti = () => {
    return JSON.parse(localStorage.getItem("skladApatiti"));
  };

  const skladMyrmansk = () => {
    return JSON.parse(localStorage.getItem("skladMyrmansk"));
  };

  return {
    collectedGoodsNumber,
    checkedGoodsNumber,
    dateFormat,
    saveDocCorrection,
    getDocsCorrection,
    getPersons,
    getProducts,
    getDocCorrection,
    getDocsIncome,
    getDocIncome,
    getStores,
    saveDocIncome,
    getPositions,
    getOutcomeTypes,
    getIncomeTypes,
    getProductBalance,
    getSkladRevision,
    initSkladRevision,
    saveSkladRevision,
    postProductBalance,
    deleteProductBalance,
    postDefaultProductBalancePosition,
    postAutoFillProductBalancePosition,
    postSkladOrderDraftRow,
    postProductToSkladOrderDraft,
    getAutoFillPositions,
    deleteProductBalanceById,
    getSkladOrderDraft,
    postSkladOrderWeightChecked,
    Html5QrcodeObj,
    scan,
    scanner,
    settings,
    getSettings,
    saveSettings,
    scannedText,
    isMobile,
    openSkladOrder,
    getSkladOrderByInvoiceGuid,
    postDriverPriority,
    getLastInventories,
    getReportRefundsData,
    postProductBalanceToInventory,
    getProductBalance1C,
    getProductBalanceRows,
    saveOrder,
    scrollToId,
    isMarksCollected,
    getSkladInventories,
    getSkladInventory,
    saveSkladInventory,
    getSkladInventoryBalance,
    getSkladInventoryBalanceByProduct,
    saveSkladInventoryGoodsRow,
    getReportSkladBalance,
    getProductBalanceRowsByProduct,
    postUserHistory,
    postUserHistoryByMetric,
    getUserHistory,
    getSkladAkts,
    postSkladAkt1C,
    getDoc1C,
    getCatlg1C,
    saveDoc1C,
    getProductPrice1C,
    getKontragentByPartner1C,
    getUserByFIO1C,
    userSklad,
  };
};
